// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-career-tech-js": () => import("./../../../src/pages/career-tech.js" /* webpackChunkName: "component---src-pages-career-tech-js" */),
  "component---src-pages-cloud-service-vsaas-js": () => import("./../../../src/pages/cloud-service-vsaas.js" /* webpackChunkName: "component---src-pages-cloud-service-vsaas-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-ict-surveillance-solutions-js": () => import("./../../../src/pages/ict-surveillance-solutions.js" /* webpackChunkName: "component---src-pages-ict-surveillance-solutions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-cloud-services-js": () => import("./../../../src/pages/it-cloud-services.js" /* webpackChunkName: "component---src-pages-it-cloud-services-js" */),
  "component---src-pages-manage-services-js": () => import("./../../../src/pages/manage-services.js" /* webpackChunkName: "component---src-pages-manage-services-js" */),
  "component---src-pages-software-development-js": () => import("./../../../src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */)
}

